import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
          <path d="M4306 8958 c-8 -13 -31 -66 -51 -118 -20 -52 -43 -108 -50 -125 -7
-16 -37 -91 -65 -165 -29 -74 -59 -149 -66 -166 -7 -17 -34 -84 -60 -150 -25
-65 -52 -132 -59 -149 -8 -16 -53 -129 -100 -250 -47 -121 -90 -229 -94 -240
-5 -11 -16 -36 -24 -55 -9 -19 -25 -62 -37 -95 -12 -33 -30 -78 -39 -100 -10
-22 -57 -141 -106 -265 -48 -124 -95 -243 -105 -265 -9 -22 -50 -123 -90 -225
-40 -102 -79 -198 -86 -215 -7 -16 -92 -230 -189 -475 -97 -245 -220 -553
-272 -684 -85 -214 -95 -243 -89 -283 3 -25 9 -49 12 -55 13 -21 113 6 478
127 205 69 390 125 410 125 33 0 36 -2 36 -29 0 -17 -4 -33 -10 -36 -5 -3 -10
-14 -10 -24 0 -10 -8 -44 -19 -77 -10 -32 -27 -86 -36 -119 -10 -33 -28 -94
-40 -135 -13 -41 -33 -109 -44 -150 -12 -41 -33 -111 -46 -155 -27 -89 -65
-214 -97 -320 -11 -38 -24 -81 -28 -95 -5 -14 -16 -52 -25 -85 -10 -33 -26
-85 -36 -115 -33 -99 -112 -374 -116 -405 -6 -41 10 -100 27 -100 7 0 31 25
53 55 68 96 357 536 516 785 143 225 435 681 573 895 71 109 112 173 229 355
45 72 152 238 237 370 166 257 242 388 242 414 0 9 -5 33 -11 55 -9 34 -13 38
-37 33 -49 -9 -206 -39 -257 -48 -78 -14 -145 -22 -145 -17 0 3 43 149 96 324
53 175 104 348 115 383 41 139 82 187 122 143 14 -15 141 -368 222 -617 8 -22
79 -233 160 -470 81 -236 215 -633 300 -881 152 -449 168 -492 189 -506 6 -4
259 -8 562 -8 444 0 554 3 568 13 29 22 27 29 -191 634 -24 68 -64 180 -89
250 -25 71 -68 191 -95 268 -28 77 -62 172 -76 210 -13 39 -33 93 -43 120 -10
28 -30 83 -44 123 -14 39 -37 102 -50 140 -28 75 -86 238 -171 477 -31 88 -63
178 -72 200 -8 22 -60 166 -114 320 -55 154 -111 309 -124 345 -13 36 -72 200
-130 365 -109 306 -161 451 -195 540 -10 28 -33 91 -51 140 -56 160 -70 199
-90 243 l-19 42 -520 0 -520 0 -14 -22z"/>
<path d="M7201 8068 c0 -513 2 -778 9 -778 12 0 12 1400 0 1495 -4 33 -8 -290
-9 -717z"/>
<path d="M9972 6674 c-2 -1832 -4 -2153 -16 -2163 -10 -9 -174 -12 -625 -11
l-611 0 6 -28 c14 -60 -16 -57 597 -60 309 -1 580 1 601 5 33 7 41 14 48 40 4
17 7 1007 5 2199 l-2 2169 -3 -2151z"/>
<path d="M5931 8744 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5940 8713 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M5971 8635 c-1 -5 8 -32 19 -60 10 -27 19 -43 19 -35 0 17 -38 110
-38 95z"/>
<path d="M6010 8513 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M6030 8458 c0 -7 5 -20 10 -28 8 -12 10 -11 10 7 0 12 -4 25 -10 28
-5 3 -10 0 -10 -7z"/>
<path d="M6052 8400 c41 -124 63 -179 66 -170 3 7 0 22 -6 33 -6 12 -18 47
-28 77 -10 30 -22 60 -27 65 -7 6 -8 5 -5 -5z"/>
<path d="M6120 8203 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M6140 8148 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
<path d="M6161 8094 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6170 8069 c0 -15 33 -99 37 -95 6 5 -17 76 -29 91 -4 5 -8 7 -8 4z"/>
<path d="M6221 7925 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M6240 7863 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M6261 7815 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M6281 7755 c0 -6 7 -26 15 -45 l14 -35 -6 35 c-5 31 -25 69 -23 45z"/>
<path d="M6310 7665 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M6331 7615 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M6350 7555 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M6371 7504 c-1 -7 6 -27 14 -45 8 -19 14 -27 14 -19 0 18 -27 79 -28
64z"/>
<path d="M6400 7419 c0 -15 33 -99 37 -95 6 5 -17 76 -29 91 -4 5 -8 7 -8 4z"/>
<path d="M6440 7305 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M6461 7250 c0 -8 6 -28 14 -45 7 -16 14 -23 14 -15 0 8 -6 29 -14 45
-7 17 -14 23 -14 15z"/>
<path d="M6491 7165 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M6510 7103 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M6530 7045 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M6551 6994 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6561 6965 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M6585 6890 c12 -41 44 -116 44 -105 0 15 -35 114 -44 123 -4 4 -4 -4
0 -18z"/>
<path d="M6630 6763 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M6650 6703 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M6675 6640 c13 -43 44 -116 44 -105 1 15 -34 114 -44 123 -4 4 -4 -4
0 -18z"/>
<path d="M6721 6515 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M6740 6453 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M6764 6395 c12 -49 69 -206 73 -202 5 5 -55 185 -69 207 -7 12 -8 11
-4 -5z"/>
<path d="M7207 6173 c-4 -3 -7 -193 -7 -421 0 -229 2 -413 4 -411 3 2 8 153
11 334 7 350 10 370 58 397 17 10 167 13 697 13 628 0 677 -1 708 -18 l32 -18
0 60 c0 44 -4 60 -16 65 -21 8 -1479 8 -1487 -1z"/>
<path d="M6860 6119 c0 -15 33 -99 37 -95 6 5 -17 76 -29 91 -4 5 -8 7 -8 4z"/>
<path d="M6900 6009 c0 -15 33 -99 37 -95 6 5 -17 76 -29 91 -4 5 -8 7 -8 4z"/>
<path d="M6957 5839 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M6970 5803 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M6991 5754 c-1 -7 6 -27 14 -45 8 -19 14 -27 14 -19 0 18 -27 79 -28
64z"/>
<path d="M7020 5669 c0 -15 33 -99 37 -95 6 5 -17 76 -29 91 -4 5 -8 7 -8 4z"/>
<path d="M7063 5547 c4 -11 10 -30 13 -43 3 -13 10 -24 14 -24 9 0 -12 66 -25
77 -4 5 -5 0 -2 -10z"/>
<path d="M7178 5302 c-21 -4 -21 -1 -7 -41 13 -37 26 -40 35 -7 7 31 -6 53
-28 48z"/>
<path d="M7792 4183 c-113 -34 -192 -142 -192 -264 0 -246 284 -365 479 -201
l32 27 -49 55 -50 55 -23 -21 c-36 -32 -64 -44 -105 -44 -29 0 -43 7 -70 36
-30 32 -34 41 -34 92 0 89 36 131 112 132 39 0 54 -6 84 -32 l36 -32 49 54
c27 30 49 58 49 63 0 13 -94 67 -144 82 -61 18 -106 18 -174 -2z"/>
<path d="M8316 4184 c-106 -33 -173 -105 -195 -211 -14 -66 -6 -116 32 -192
50 -101 196 -160 328 -132 85 18 151 68 188 142 115 230 -100 470 -353 393z
m154 -147 c54 -42 68 -140 29 -203 -46 -75 -148 -64 -189 20 -11 23 -20 52
-20 64 0 36 25 87 55 110 31 25 100 29 125 9z"/>
<path d="M9607 4183 c-56 -19 -102 -67 -116 -121 -23 -84 29 -155 135 -187
132 -39 153 -55 123 -92 -23 -27 -89 -9 -231 65 -4 2 -21 -24 -37 -57 l-31
-60 28 -20 c52 -37 151 -64 238 -64 132 -1 211 51 222 148 10 97 -28 140 -164
185 -51 17 -97 37 -103 44 -18 22 -2 46 30 46 29 0 137 -36 154 -51 5 -5 14
-9 20 -9 13 0 67 118 60 131 -3 4 -31 17 -63 29 -77 30 -201 36 -265 13z"/>
<path d="M4432 4173 c-5 -10 -40 -90 -77 -178 -37 -88 -82 -195 -101 -238 -19
-43 -34 -85 -34 -92 0 -12 17 -15 83 -15 l84 0 12 40 12 41 106 -3 106 -3 12
-35 12 -35 91 -3 c51 -1 92 -1 92 1 0 2 -48 122 -108 268 l-107 264 -87 3
c-75 2 -88 0 -96 -15z m120 -243 c14 -38 23 -72 20 -75 -3 -3 -30 -5 -60 -5
-39 0 -53 3 -49 13 2 6 14 40 27 75 12 34 26 62 30 62 4 0 19 -31 32 -70z"/>
<path d="M4865 4178 c-3 -7 -4 -128 -3 -268 l3 -255 88 -3 87 -3 0 101 0 100
85 0 85 0 0 -100 0 -100 85 0 85 0 0 270 0 271 -82 -3 -83 -3 -3 -102 -3 -103
-84 0 -84 0 -3 103 -3 102 -83 3 c-61 2 -84 -1 -87 -10z"/>
<path d="M5597 4184 c-4 -4 -7 -36 -7 -71 l0 -63 75 0 75 0 0 -200 0 -201 88
3 87 3 3 198 2 197 76 0 75 0 -3 68 -3 67 -231 3 c-127 1 -233 -1 -237 -4z"/>
<path d="M6127 4184 c-4 -4 -7 -126 -7 -271 l0 -263 230 0 230 0 0 65 0 65
-146 0 -145 0 3 38 3 37 123 3 122 3 0 59 0 59 -122 3 -123 3 0 35 0 35 138 3
138 3 -3 62 -3 62 -216 3 c-118 1 -218 -1 -222 -4z"/>
<path d="M6657 4184 c-4 -4 -7 -126 -7 -271 l0 -263 195 0 195 0 0 70 0 69
-107 3 -108 3 -5 195 -5 195 -75 3 c-42 1 -79 0 -83 -4z"/>
<path d="M7107 4184 c-4 -4 -7 -126 -7 -271 l0 -263 230 0 230 0 0 65 0 64
-142 3 -143 3 0 35 0 35 123 3 122 3 0 59 0 59 -122 3 -123 3 0 35 0 35 138 3
138 3 -3 62 -3 62 -216 3 c-118 1 -218 -1 -222 -4z"/>
<path d="M8755 4178 c-3 -7 -4 -128 -3 -268 l3 -255 78 -3 77 -3 0 143 c1 161
-2 161 65 13 l41 -90 59 -3 60 -3 49 111 c26 60 52 107 57 104 5 -3 9 -66 9
-140 l0 -134 75 0 75 0 -2 268 -3 267 -90 0 -90 0 -68 -140 c-61 -124 -70
-138 -80 -120 -6 11 -37 74 -69 140 l-57 120 -91 3 c-67 2 -92 -1 -95 -10z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
